import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  baseURL: string = environment.baseURL + '/fudzo-main-service/api/v1';

  constructor(
    private http : HttpClient
  ) { }

  individualTopUp(data): Observable<any> {
    return this.http.post(`${this.baseURL}/transaction/individual-admin-topup`, data);
  }

  companyTopUp(data): Observable<any> {
    return this.http.post(`${this.baseURL}/transaction/company-admin-topup`, data);
  }

  approve(ref): Observable<any> {
    return this.http.post(`${this.baseURL}/transaction/admin-approve?transactionReference=${ref}`, null);
  }

  cancel(ref): Observable<any> {
    return this.http.post(`${this.baseURL}/transaction/admin-cancel?transactionReference=${ref}`, null);
  }

  reverse(ref): Observable<any> {
    return this.http.post(`${this.baseURL}/transaction/admin-reverse?transactionReference=${ref}`, null);
  }

  adjustment(data): Observable<any> {
    return this.http.post(`${this.baseURL}/transaction/admin-adjustment`, data);
  }

  // transaction reports
  getPending(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/organisation-pending`, data);
  }

  getOrganisationTransactions(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/organisation`, data);
  }

  // account transfers
  individualTransfer(data): Observable<any> {
    return this.http.post(`${this.baseURL}/transaction/individual-transfer`, data);
  }

  companyTransfer(data): Observable<any> {
    return this.http.post(`${this.baseURL}/transaction/company-transfer`, data);
  }

  cardToCard(data, typ): Observable<any> {
    return typ == 'INDIVIDUAL' ? 
    this.http.post(`${this.baseURL}/transaction/individual-card-to-card`, data) :
    this.http.post(`${this.baseURL}/transaction/company-card-to-card`, data);
  }

  cardToAccount(data, typ): Observable<any> {
    return typ == 'INDIVIDUAL' ? 
    this.http.post(`${this.baseURL}/transaction/individual-card-to-account`, data) :
    this.http.post(`${this.baseURL}/transaction/company-card-to-account`, data);
  }

  accountToCard(data, typ): Observable<any> {
    return typ == 'INDIVIDUAL' ? 
    this.http.post(`${this.baseURL}/transaction/individual-transfer`, data) :
    this.http.post(`${this.baseURL}/transaction/company-transfer`, data);
  }

}
